"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamsLayout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Group_1 = require("../rows/Group");
const TeamsLayout = (props) => {
    if (props.teams.length === 0)
        return null;
    if (props.teams.length === 1) {
        return props.children({ team: props.teams[0] });
    }
    return props.teams.map((team) => {
        return ((0, jsx_runtime_1.jsx)(Group_1.Group, { id: team.id, title: team.name, isTeam: true, children: props.children({ team }) }, team.id));
    });
};
exports.TeamsLayout = TeamsLayout;
