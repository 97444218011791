"use strict";
// Source: https://github.com/iamkun/dayjs/pull/2060/files
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseZone = void 0;
const REGEX_TIMEZONE_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;
const parseOffset = (dateString) => dateString.match(REGEX_TIMEZONE_OFFSET_FORMAT);
const formatOffset = (parsedOffset) => {
    const [, , sign, tzHour, tzMinute] = parsedOffset;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const uOffset = parseInt(tzHour, 10) * 60 + parseInt(tzMinute, 10);
    return sign === '+' ? uOffset : -uOffset;
};
/**
 * @authors https://github.com/iamkun/dayjs/pull/2060/files
 * @see https://github.com/iamkun/dayjs/issues/651
 *
 * This plugins depends on the UTC plugin. To support the custom format option,
 * you will need the CustomParseFormat plugin too.
 */
const parseZone = (_option, _dayjsClass, dayjsFactory) => {
    dayjsFactory.parseZone = function (date, format, locale, strict) {
        const formatOption = typeof format === 'string' ? { format } : format;
        if (typeof date !== 'string') {
            return dayjsFactory(date, formatOption, locale, strict);
        }
        const match = parseOffset(date);
        if (match === null) {
            return dayjsFactory(date, {
                $offset: 0,
            });
        }
        if (match[0] === 'Z') {
            return dayjsFactory(date, {
                utc: true,
                ...formatOption,
            }, locale, strict);
        }
        const [, dateTime] = match;
        const offset = formatOffset(match);
        return dayjsFactory(dateTime, {
            $offset: offset,
            ...formatOption,
        }, locale, strict);
    };
};
exports.parseZone = parseZone;
