"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEUWeeklyRestRuleViolations = exports.getEUDailyRestRuleViolations = void 0;
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const get_slots_1 = require("../utils/get-slots");
const DAY_SLOT_LENGTH = 24;
const WEEK_SLOT_LENGTH = 7 * DAY_SLOT_LENGTH;
const getEUDailyRestRuleViolations = (activeTimes, rules, dayBreak) => {
    const startsAt = activeTimes[0].startsAt;
    const endsAt = activeTimes[activeTimes.length - 1].endsAt;
    const firstSlotStartsAt = startsAt.time(dayBreak).subtract(1, 'day');
    const numberOfSlots = endsAt.diff(firstSlotStartsAt, 'days') + 1;
    const slots = (0, get_slots_1.getSlots)(firstSlotStartsAt, numberOfSlots, DAY_SLOT_LENGTH, activeTimes);
    const RECOMMENDED_DAILY_REST_TIME = rules.recommendedDailyRest;
    const MINIMUM_DAILY_REST_TIME = rules.minimumDailyRest;
    // If the resource only had the minimum rest time the next day we need to compensate
    const REST_WITH_MAXIMUM_COMPENSATION = RECOMMENDED_DAILY_REST_TIME +
        (RECOMMENDED_DAILY_REST_TIME - MINIMUM_DAILY_REST_TIME);
    const isRecommendedRest = (slot) => {
        return slot.restTime >= RECOMMENDED_DAILY_REST_TIME;
    };
    const isMinimumRest = (slot) => {
        return slot.restTime >= MINIMUM_DAILY_REST_TIME;
    };
    return slots
        .map((slot, i) => {
        // If the rest time is recommended + maximum compensation you can accrue we know it's fine
        if (slot.restTime >= REST_WITH_MAXIMUM_COMPENSATION) {
            return null;
        }
        if (!isMinimumRest(slot)) {
            return {
                date: new Set([slot.date]),
                shiftId: new Set(slot.activeTime.map(({ shiftId }) => shiftId)),
                severity: 'error',
                type: enums_1.SchedulingViolationType.EU_NO_DAILY_REST,
                details: { restTime: slot.restTime },
            };
        }
        // Control that if the previous day had the minimum rest we compensate on this day
        const prevSlotRestTime = i > 0 ? slots[i - 1].restTime : RECOMMENDED_DAILY_REST_TIME;
        if (!isRecommendedRest({ restTime: prevSlotRestTime })) {
            const diffWithRecommendedRest = RECOMMENDED_DAILY_REST_TIME - prevSlotRestTime;
            // Make sure current slot has the recommended rest AND the diff from the prev day
            if (slot.restTime <
                RECOMMENDED_DAILY_REST_TIME + diffWithRecommendedRest) {
                return {
                    date: new Set([slot.date]),
                    shiftId: new Set(slot.activeTime.map(({ shiftId }) => shiftId)),
                    severity: 'error',
                    type: enums_1.SchedulingViolationType.EU_NO_COMPENSATING_REST,
                    details: { restTime: slot.restTime },
                };
            }
        }
        if (!isRecommendedRest(slot)) {
            return {
                date: new Set([slot.date]),
                shiftId: new Set(slot.activeTime.map(({ shiftId }) => shiftId)),
                severity: 'warning',
                type: enums_1.SchedulingViolationType.EU_REDUCED_DAILY_REST,
                details: { restTime: slot.restTime },
            };
        }
        return null;
    })
        .filter(utils_1.isTruthy);
};
exports.getEUDailyRestRuleViolations = getEUDailyRestRuleViolations;
const getEUWeeklyRestRuleViolations = (activeTimes, rules, 
/*
 * "D HH:mm" where D is isoWeekday number https://day.js.org/docs/en/get-set/iso-weekday
 */
weekBreak) => {
    const weekBreakComponents = weekBreak.split(' ');
    const weekDay = parseInt(weekBreakComponents[0]);
    const time = weekBreakComponents[1];
    const startsAt = activeTimes[0].startsAt;
    const endsAt = activeTimes[activeTimes.length - 1].endsAt;
    let firstSlotStartsAt = startsAt.isoWeekday(weekDay).time(time);
    // Make sure the first active time is part of the first slot
    if (firstSlotStartsAt.isAfter(startsAt)) {
        firstSlotStartsAt = firstSlotStartsAt.subtract(1, 'week');
    }
    const numberOfSlots = endsAt.diff(firstSlotStartsAt, 'weeks') + 1;
    const slots = (0, get_slots_1.getSlots)(firstSlotStartsAt, numberOfSlots, WEEK_SLOT_LENGTH, activeTimes);
    const MINIMUM_WEEKLY_REST_TIME = rules.minimumWeeklyRest;
    return slots
        .map((slot) => {
        if (slot.restTime < MINIMUM_WEEKLY_REST_TIME) {
            return {
                date: new Set([slot.date]),
                shiftId: new Set(slot.activeTime.map(({ shiftId }) => shiftId)),
                details: { restTime: slot.restTime },
                severity: 'error',
                type: enums_1.SchedulingViolationType.EU_NO_WEEKLY_REST,
            };
        }
        return null;
    })
        .filter(utils_1.isTruthy);
};
exports.getEUWeeklyRestRuleViolations = getEUWeeklyRestRuleViolations;
