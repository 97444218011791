"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalResources = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const utils_1 = require("@bemlo/utils");
const translate_1 = require("../../../../translate");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const NewShift_1 = require("../components/NewShift");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const ExternalResources = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { days, currentWeek, shifts: allShifts, shiftPopover, resourcesIdMap, resourcesRowKeyMap, } = (0, useSchedule_1.useSchedule)();
    const [shifts, shiftsInWeek] = (0, react_1.useMemo)(() => {
        const shiftsWithBookings = allShifts
            .map((shift) => {
            if (shift.bookedCandidateId) {
                return {
                    ...shift,
                    bookedCandidate: resourcesIdMap.get(shift.bookedCandidateId),
                };
            }
            if (typeof shift.rowKey === 'number') {
                return {
                    ...shift,
                    bookedCandidate: resourcesRowKeyMap.get(shift.rowKey),
                };
            }
        })
            .filter(utils_1.isTruthy);
        const shifts = shiftsWithBookings.filter((shift) => {
            if (!props.scheduleResourceIds.includes(shift.scheduleResourceId)) {
                return false;
            }
            return shift.bookedCandidate
                ? shift.bookedCandidate.homeUnitId !== props.homeUnitId
                : false;
        });
        const shiftsInWeek = currentWeek
            ? (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, shifts)
            : shifts;
        return [shifts, shiftsInWeek];
    }, [allShifts, props, currentWeek]);
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { title: t.shifts.external_resource_other(), shifts: shiftsInWeek, bold: false }) }), days.map((day) => {
                const shiftsOnDay = shifts.filter((shift) => shift.date === day.dateISO);
                return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: !day.withinRange, weekend: day.isWeekend, children: (0, jsx_runtime_1.jsx)(CellShiftsColumn_1.CellShiftsColumn, { children: shiftsOnDay.map((shift) => {
                            const isArchived = shift.status === 'ARCHIVED';
                            const isUrgent = shift.isUrgent && !isArchived;
                            if (!shift)
                                return null;
                            const resource = shift.bookedCandidate
                                ? shift.bookedCandidate.fullName
                                : shift.rowKey !== undefined
                                    ? t.shifts.row_n(shift.rowKey + 1)
                                    : t.shifts.vacant_one();
                            return ((0, jsx_runtime_1.jsx)(NewShift_1.NewShift, { shift: shift, interestedCandidates: shift.interestedCandidates, unitName: `${resource} - ${shift.scheduleResourceName}`, popoverContent: shiftPopover, shiftIds: [shift.id], status: shift.status, isUrgent: isUrgent, unselectable: isArchived }, shift.id));
                        }) }) }, day.dateISO));
            })] }));
};
exports.ExternalResources = ExternalResources;
