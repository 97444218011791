"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedulerPenaltyType = void 0;
var SchedulerPenaltyType;
(function (SchedulerPenaltyType) {
    /* VACANCY MANAGEMENT */
    SchedulerPenaltyType["VACANT_SLOT"] = "VACANT_SLOT";
    SchedulerPenaltyType["MAX_DAILY_VACANCIES"] = "MAX_DAILY_VACANCIES";
    SchedulerPenaltyType["USED_EXTERNAL_RESOURCE"] = "USED_EXTERNAL_RESOURCE";
    SchedulerPenaltyType["MAX_SLOT_FLEX"] = "MAX_SLOT_FLEX";
    SchedulerPenaltyType["FLEX_SHIFT_WHILE_VACANCY"] = "FLEX_SHIFT_WHILE_VACANCY";
    SchedulerPenaltyType["OVERSTAFFED_SLOT"] = "OVERSTAFFED_SLOT";
    /* FAIRNESS */
    SchedulerPenaltyType["SHIFT_HOURS_DAY_IMBALANCE"] = "SHIFT_HOURS_DAY_IMBALANCE";
    SchedulerPenaltyType["MAX_SHIFT_HOURS_DAY_IMBALANCE"] = "MAX_SHIFT_HOURS_DAY_IMBALANCE";
    SchedulerPenaltyType["DAYS_OFF_FAIRNESS"] = "DAYS_OFF_FAIRNESS";
    SchedulerPenaltyType["DAYS_ON_FAIRNESS"] = "DAYS_ON_FAIRNESS";
    SchedulerPenaltyType["PREFERENCE_ADHERENCE_FAIRNESS"] = "PREFERENCE_ADHERENCE_FAIRNESS";
    SchedulerPenaltyType["MAX_HOLIDAYS_WORKED"] = "MAX_HOLIDAYS_WORKED";
    /* MULTI_UNIT */
    SchedulerPenaltyType["SUB_QUALIFIED_SLOT"] = "SUB_QUALIFIED_SLOT";
    /* HEALTH */
    SchedulerPenaltyType["SHORT_DAILY_REST"] = "SHORT_DAILY_REST";
    SchedulerPenaltyType["FIRST_REST_DAY"] = "FIRST_REST_DAY";
    SchedulerPenaltyType["EVENING_TO_DAY"] = "EVENING_TO_DAY";
    /* WORKLOAD */
    SchedulerPenaltyType["OUTGOING_SLOTS"] = "OUTGOING_SLOTS";
    SchedulerPenaltyType["MAX_OUTGOING_SLOTS"] = "MAX_OUTGOING_SLOTS";
    SchedulerPenaltyType["MAX_WORKLOAD_PER_WEEK"] = "MAX_WORKLOAD_PER_WEEK";
    /* PREFERENCE */
    SchedulerPenaltyType["DAY_OFF_PREFERENCE_GRANTED"] = "DAY_OFF_PREFERENCE_GRANTED";
    SchedulerPenaltyType["DAY_ON_PREFERENCE_GRANTED"] = "DAY_ON_PREFERENCE_GRANTED";
    SchedulerPenaltyType["SHIFT_ON_PREFERENCE_GRANTED"] = "SHIFT_ON_PREFERENCE_GRANTED";
    SchedulerPenaltyType["SHIFT_OFF_PREFERENCE_GRANTED"] = "SHIFT_OFF_PREFERENCE_GRANTED";
    SchedulerPenaltyType["PREFERENCE"] = "PREFERENCE";
    /* OTHER */
    SchedulerPenaltyType["SHORT_WORKDAY"] = "SHORT_WORKDAY";
    SchedulerPenaltyType["BASE_SCHEDULE_DEVIATION"] = "BASE_SCHEDULE_DEVIATION";
})(SchedulerPenaltyType || (exports.SchedulerPenaltyType = SchedulerPenaltyType = {}));
