"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FLOW_RULE_SET = exports.SWEDISH_RULE_SET = exports.getRuleSetsForCountry = void 0;
const enums_1 = require("@bemlo/enums");
const SWEDISH_RULE_SET = [
    'SWEDISH_WORKING_TIME_DIRECTIVE',
    'SHIFT_REST',
    'WORKLOAD',
];
exports.SWEDISH_RULE_SET = SWEDISH_RULE_SET;
const FLOW_RULE_SET = ['SHIFT_REST'];
exports.FLOW_RULE_SET = FLOW_RULE_SET;
const getRuleSetsForCountry = (country) => {
    if (country === enums_1.Country.SWEDEN) {
        return SWEDISH_RULE_SET;
    }
    return ['SHIFT_REST'];
};
exports.getRuleSetsForCountry = getRuleSetsForCountry;
