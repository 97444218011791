"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRuleViolationsMap = exports.getRuleViolations = void 0;
const lodash_1 = require("lodash");
const eu_rest_1 = require("../checks/eu-rest");
const shift_rest_1 = require("../checks/shift-rest");
const workload_1 = require("../checks/workload");
const get_slots_1 = require("../utils/get-slots");
// TODO(ef): should we add regex for day and week breaks? How to handle that? Notify or throw error?
const getRuleViolations = (ruleSets, unsortedShifts, rules, resourceConfiguration) => {
    if (!unsortedShifts.length)
        return [];
    const shifts = (0, lodash_1.sortBy)(unsortedShifts, 'startDateTime');
    const activeTimes = (0, get_slots_1.getActiveTime)(shifts);
    const violations = [];
    if (ruleSets.includes('SWEDISH_WORKING_TIME_DIRECTIVE')) {
        const euRestRules = {
            minimumDailyRest: rules.get('minimumDailyRest'),
            recommendedDailyRest: rules.get('recommendedDailyRest'),
            minimumWeeklyRest: rules.get('minimumWeeklyRest'),
        };
        violations.push(...(0, eu_rest_1.getEUDailyRestRuleViolations)(activeTimes, euRestRules, resourceConfiguration.dayBreak), ...(0, eu_rest_1.getEUWeeklyRestRuleViolations)(activeTimes, euRestRules, resourceConfiguration.weekBreak), ...(0, workload_1.getEUWorkloadRuleViolations)(shifts));
    }
    if (ruleSets.includes('SHIFT_REST')) {
        const shiftRestRules = {
            minimumRestBetweenShifts: rules.get('minimumRestBetweenShifts'),
        };
        violations.push(...(0, shift_rest_1.getShiftRestRuleViolations)(activeTimes, shiftRestRules));
    }
    if (ruleSets.includes('WORKLOAD')) {
        violations.push(...(0, workload_1.getWorkloadRuleViolations)(shifts, resourceConfiguration));
    }
    return violations;
};
exports.getRuleViolations = getRuleViolations;
const getRuleViolationsMap = (...args) => {
    const errors = getRuleViolations(...args);
    if (!errors.length)
        return {};
    return errors.reduce((acc, error) => {
        const keys = [...error.date, ...error.shiftId];
        for (const key of keys) {
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(error);
        }
        return acc;
    }, {});
};
exports.getRuleViolationsMap = getRuleViolationsMap;
