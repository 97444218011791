"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSchedulingRule = exports.schedulingRules = void 0;
const defaults_1 = require("./defaults");
const schema_1 = require("./schema");
const getSchedulingRule = (rules, key) => {
    const value = rules[key];
    if (value) {
        const parsed = schema_1.SchedulingRulesSchema.shape[key].safeParse(value);
        if (parsed.success) {
            return parsed.data;
        }
    }
    return defaults_1.DEFAULT_SCHEDULING_RULES[key];
};
exports.getSchedulingRule = getSchedulingRule;
const schedulingRules = (rules) => {
    return {
        get: (key) => {
            return getSchedulingRule(rules, key);
        },
    };
};
exports.schedulingRules = schedulingRules;
