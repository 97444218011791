"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SCHEDULING_RULES = void 0;
const utils_1 = require("@bemlo/utils");
const DEFAULT_SCHEDULING_RULES = {
    recommendedConsecutiveShifts: 5,
    maximumConsecutiveShifts: 5,
    minimumNightToDayRest: (0, utils_1.hoursAsMinutes)(28),
    minimumRestBetweenShifts: (0, utils_1.hoursAsMinutes)(8),
    minimumDailyRest: (0, utils_1.hoursAsMinutes)(9),
    recommendedDailyRest: (0, utils_1.hoursAsMinutes)(11),
    minimumWeeklyRest: (0, utils_1.hoursAsMinutes)(36),
    commonDayBreak: null,
    defaultWeekendFrequency: '2/4',
    maxOutgoingHours: null,
    shortDayLength: (0, utils_1.hoursAsMinutes)(5),
    longestDayLength: (0, utils_1.hoursAsMinutes)(13),
    noShortDays: false,
    minLengthPaddingShifts: null,
    extendedLeave: null,
    penalties: {},
};
exports.DEFAULT_SCHEDULING_RULES = DEFAULT_SCHEDULING_RULES;
