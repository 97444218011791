"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schedulingViolationType = void 0;
exports.schedulingViolationType = {
    EU_MAX_HOURS_PER_28_DAYS_EXCEEDED: 'Max 48-hour average per 4 weeks',
    EU_NO_COMPENSATING_REST: 'Compensatory rest',
    EU_NO_DAY_REST: 'Daily rest',
    EU_NO_WEEK_REST: 'Weekly rest',
    EU_REDUCED_DAY_REST: 'Daily rest',
    INSUFFICIENT_REST_BETWEEN_SHIFTS: 'Rest between shifts',
    MAX_HOURS_PER_DAY_EXCEEDED: 'Too long workday',
    MAX_HOURS_PER_PERIOD_EXCEEDED: 'Overtime',
    OVERLAPPING_SHIFTS: 'Overlapping shifts',
    SHORT_DAY_WORKED: 'Too short workday',
};
