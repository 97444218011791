"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenseStatuses = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const Detail_1 = require("../../components/Detail");
const useCandidateView_1 = require("../../hooks/useCandidateView");
const scopes_1 = require("../../scopes");
const LicenseStatuses = () => {
    const { actor, data, isEditing, market, update } = (0, useCandidateView_1.useCandidateView)();
    const t = (0, translate_1.useTranslations)();
    const { norwegianLicenseStatus, profession, swedishLicenseStatus } = data;
    const scope = scopes_1.scopes.biography.qualifications.licenseStatuses();
    if (!actor.can('read', scope)) {
        return null;
    }
    const licenseStatuses = [
        {
            field: 'norwegianLicenseStatus',
            label: t.candidate_view.biography.norwegian_license(),
            value: norwegianLicenseStatus,
            market: enums_1.Market.NO,
        },
        {
            field: 'swedishLicenseStatus',
            label: t.candidate_view.biography.swedish_license(),
            value: swedishLicenseStatus,
            market: enums_1.Market.SE,
        },
    ].sort((a, b) => (b.market === market ? 1 : 0) - (a.market === market ? 1 : 0));
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Flex, { gap: 3.5, wrap: true, children: licenseStatuses.map(({ field, label, value }) => {
                return ((0, jsx_runtime_1.jsx)(elements_1.Combobox, { disabled: !actor.can('update', scope), label: label, onChange: update(field), options: Object.entries(enums_1.LicenseStatus).map(([key, value]) => ({
                        key,
                        label: t.candidate_view.license_status(value),
                        value,
                    })), value: value }, field));
            }) }));
    }
    const selectedLicenseStatuses = licenseStatuses.filter((license) => license.value && license.value !== enums_1.LicenseStatus.NO_LICENSE);
    if (!selectedLicenseStatuses.length) {
        return null;
    }
    const hasLicenseIn = selectedLicenseStatuses
        .filter((license) => license.value === enums_1.LicenseStatus.HAS_LICENSE)
        .map((license) => license.market);
    const hasAppliedForLicenseIn = selectedLicenseStatuses
        .filter((license) => license.value === enums_1.LicenseStatus.HAS_APPLIED)
        .map((license) => license.market);
    const licenseText = [
        {
            markets: hasLicenseIn,
            translate: t.candidate_view.biography.licensed_in,
        },
        {
            markets: hasAppliedForLicenseIn,
            translate: t.candidate_view.biography.has_applied_for_license_in,
        },
    ]
        .filter(({ markets }) => {
        return markets.length >= 1;
    })
        .map(({ markets, translate }, index) => {
        const text = translate(markets, !!hasLicenseIn.length);
        return index === 0
            ? `${text.slice(0, 1).toUpperCase()}${text.slice(1)}`
            : text;
    })
        .join(', ');
    return ((0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: licenseText, heading: hasLicenseIn.length === 0
            ? t.candidate_view.biography.applied_for_license()
            : t.candidate_view.biography.licensed(profession), icon: "KeyIcon" }));
};
exports.LicenseStatuses = LicenseStatuses;
