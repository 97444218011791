"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resource = void 0;
const react_1 = require("react");
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_2 = require("react");
const schedule_1 = require("@bemlo/schedule");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const DraftShifts_1 = require("../components/DraftShifts");
const NewShift_1 = require("../components/NewShift");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const ShiftError_1 = require("../components/ShiftError");
const useEditShifts_1 = require("../useEditShifts");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const CANDIDATE_STATUSES = new Set(['BOOKED', 'SUGGESTED', 'DRAFT']);
const Resource = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { days, currentWeek, shifts: allShifts, availability: allAvailability, shiftPopover, isScrolled, } = (0, useSchedule_1.useSchedule)();
    const { enableEditing, onCreateDraftShift, onDeleteDraftShift } = (0, useEditShifts_1.useEditShifts)(props.draftShiftInput);
    const { candidateShifts, candidateAvailability, dayErrors } = (0, react_2.useMemo)(() => {
        const candidateShifts = allShifts.filter((0, by_filter_1.byCellFilter)({
            scheduleResourceId: props.scheduleResourceId,
            bookedCandidateId: props.candidateId,
        }));
        const candidateAvailability = allAvailability.filter(({ candidateId }) => candidateId === props.candidateId);
        const activeCandidateShifts = candidateShifts.filter((shift) => shift.status !== 'CANCELLED');
        const dayErrors = props.schedulingRules
            ? (0, schedule_1.getRuleViolationsMap)(props.schedulingRules.ruleSets, activeCandidateShifts, props.schedulingRules.rules, props.schedulingRules.resource)
            : {};
        return {
            candidateShifts,
            candidateAvailability,
            dayErrors,
        };
    }, [
        allShifts,
        props.candidateId,
        props.scheduleResourceId,
        props.schedulingRules,
    ]);
    const shiftsInWeek = currentWeek
        ? (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, candidateShifts)
        : [];
    if (!shiftsInWeek.length &&
        !candidateAvailability.length &&
        !props.permanent) {
        return null;
    }
    const bookedShifts = shiftsInWeek.filter(({ status, bookedCandidateId }) => bookedCandidateId === props.candidateId && status === 'BOOKED');
    const title = props.titleComponent ?? ((0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { bold: true, title: props.noName ? null : props.name, avatar: props.noName ? null : { url: props.avatarUrl }, url: props.resourceUrl, shifts: bookedShifts }));
    const HoverCardContent = props.hoverCard;
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, rightShadow: isScrolled, children: HoverCardContent ? ((0, jsx_runtime_1.jsx)(elements_1.HoverCard, { side: "right", trigger: (0, jsx_runtime_1.jsx)(elements_1.HoverCard.Trigger, { children: title }), children: (0, jsx_runtime_1.jsx)(HoverCardContent, { candidateId: props.candidateId, name: props.name, shifts: candidateShifts, availability: candidateAvailability, ...(props.hoverCardProps || {}) }) })) : (title) }), days.map((day) => {
                const [draftShifts, shiftsOnDay] = (0, lodash_1.partition)(candidateShifts.filter((shift) => shift.date === day.dateISO), ({ status }) => status === 'DRAFT');
                const isOccupied = shiftsOnDay.some((shift) => CANDIDATE_STATUSES.has(shift.status) && shift.bookedCandidateId);
                const availabilityOnDay = candidateAvailability.filter(({ date }) => date === day.dateISO);
                const errorsOnDay = dayErrors[day.dateISO]?.map((error) => ({
                    error: t.$schedulingRuleViolation(error),
                    isWarning: error.severity === 'warning',
                })) ?? [];
                return ((0, jsx_runtime_1.jsx)(Cell_1.AvailabilityCell, { disabled: !day.withinRange, weekend: day.isWeekend, availability: availabilityOnDay, isOccupied: isOccupied, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [shiftsOnDay.map((shift) => {
                                if (shift.status === 'DRAFT')
                                    return null;
                                return ((0, react_1.createElement)(NewShift_1.NewShift, { ...props, key: shift.id, shift: shift, status: shift.status, position: "center", shiftIds: [shift.id], isAbsent: shift.isAbsent, isAcknowledged: shift.isAcknowledged, popoverContent: shiftPopover, unitName: props.showUnitName ? shift.unitName : undefined, error: (0, jsx_runtime_1.jsx)(ShiftError_1.ShiftError, { errors: errorsOnDay }) }));
                            }), enableEditing && day.withinRange && ((0, jsx_runtime_1.jsx)(DraftShifts_1.DraftShift, { showBadges: true, shifts: draftShifts, onClickNewShift: props.draftShiftInput
                                    ? () => onCreateDraftShift(day.dateISO, 
                                    // TODO(BEMLO-3598)
                                    '')
                                    : props.onClickNewShiftType, onDeleteDraftShift: onDeleteDraftShift }))] }) }, day.dateISO));
            })] }));
};
exports.Resource = Resource;
