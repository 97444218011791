"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateSchedulingRuleViolationFactory = void 0;
const enums_1 = require("@bemlo/enums");
const i18n_1 = require("@bemlo/i18n");
const translateSchedulingRuleViolationFactory = (locale) => {
    const t = (0, i18n_1.getTranslation)('schedulingViolationType', locale);
    const n_hours = (0, i18n_1.translateNHoursFactory)(locale);
    const formatHours = (minutes) => {
        if (minutes === undefined)
            return '';
        return ` (${n_hours(minutes / 60)})`;
    };
    return ({ type, details, }) => {
        switch (type) {
            case enums_1.SchedulingViolationType.EU_NO_DAILY_REST:
            case enums_1.SchedulingViolationType.EU_NO_WEEKLY_REST:
            case enums_1.SchedulingViolationType.EU_REDUCED_DAILY_REST:
            case enums_1.SchedulingViolationType.EU_NO_COMPENSATING_REST:
            case enums_1.SchedulingViolationType.INSUFFICIENT_REST_BETWEEN_SHIFTS:
                return t[type] + formatHours(details.restTime);
            case enums_1.SchedulingViolationType.OVERLAPPING_SHIFTS:
                return t[type];
            case enums_1.SchedulingViolationType.EU_MAX_HOURS_PER_28_DAYS_EXCEEDED:
            case enums_1.SchedulingViolationType.MAX_HOURS_PER_PERIOD_EXCEEDED:
            case enums_1.SchedulingViolationType.MAX_HOURS_PER_DAY_EXCEEDED:
            case enums_1.SchedulingViolationType.SHORT_DAY_WORKED:
                return t[type] + formatHours(details.overtime);
            default:
                return t[type];
        }
    };
};
exports.translateSchedulingRuleViolationFactory = translateSchedulingRuleViolationFactory;
