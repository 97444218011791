"use strict";
// TODO(BEMLO-3823): add recommendedConsecutiveShifts rule validator
// TODO(BEMLO-3821): add maximumConsecutiveShifts rule validator
// TODO(BEMLO-3824): add minimumNightToDayRest rule validator
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShiftRestRuleViolations = void 0;
/* TODO: maybe we'll need to check more shifts than just the prev. one for conflicts. In
 * Niklas implementation he checks current day + previous day shifts. Should be easy to implement
  * if needed.
  *
  * Example from check-schedule.ts:
  * ```
      scheduleByDate.forEach((dateShifts) => {
        if (!dateShifts.length) return

        const previousDayShifts = scheduleByDate.get(
          dateShifts[0].startDateTime.subtract(1, 'day').format('YYYY-MM-DD'),
        )

        const sortedPossibleViolations = [
          ...dateShifts,
          ...(previousDayShifts ?? []),
        ].sort(dayjsShiftByTimeComparator)
      ...
    ```
  */
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const getShiftRestRuleViolations = (activeTimes, rules) => {
    const MINIMUM_DAILY_REST_TIME = rules.minimumRestBetweenShifts;
    const isSameShift = (activeTime, nextActiveTime) => {
        return activeTime.shiftId === nextActiveTime.shiftId;
    };
    return activeTimes
        .map((slot, i) => {
        // If we are on the first active time, we can't compare it to the previous one
        if (i === 0)
            return null;
        const prevActiveTime = activeTimes[i - 1];
        // Same shifts ex. divided by on call time or break should not be considered
        if (isSameShift(slot, prevActiveTime)) {
            return null;
        }
        const date = slot.startsAt.format(date_1.ISO_DATE_FORMAT);
        // If shift start right after the previous one they are back to back, no rest needed
        if (
        // TODO(BEMLO-3828): do we need the tz('UTC') here?
        slot.startsAt
            .tz('UTC')
            .isSame(prevActiveTime.endsAt.tz('UTC'), 'minute')) {
            return null;
        }
        // If the shifts overlap, return an error
        if (slot.startsAt.isBefore(prevActiveTime.endsAt)) {
            return {
                date: new Set([date]),
                shiftId: new Set([slot.shiftId, prevActiveTime.shiftId]),
                severity: 'error',
                type: enums_1.SchedulingViolationType.OVERLAPPING_SHIFTS,
                details: { restTime: 0 },
            };
        }
        const restTime = slot.startsAt.diff(prevActiveTime.endsAt, 'hours', true) * 60;
        // If the rest time is less than the minimum required, return an error
        if (restTime < MINIMUM_DAILY_REST_TIME) {
            return {
                date: new Set([date]),
                shiftId: new Set([slot.shiftId, prevActiveTime.shiftId]),
                severity: 'error',
                type: enums_1.SchedulingViolationType.INSUFFICIENT_REST_BETWEEN_SHIFTS,
                details: { restTime },
            };
        }
        return null;
    })
        .filter(utils_1.isTruthy);
};
exports.getShiftRestRuleViolations = getShiftRestRuleViolations;
