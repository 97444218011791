"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedulingViolationType = void 0;
var SchedulingViolationType;
(function (SchedulingViolationType) {
    // EU Working Time Directive
    SchedulingViolationType["EU_REDUCED_DAILY_REST"] = "EU_REDUCED_DAY_REST";
    SchedulingViolationType["EU_NO_COMPENSATING_REST"] = "EU_NO_COMPENSATING_REST";
    SchedulingViolationType["EU_NO_DAILY_REST"] = "EU_NO_DAY_REST";
    SchedulingViolationType["EU_NO_WEEKLY_REST"] = "EU_NO_WEEK_REST";
    SchedulingViolationType["EU_MAX_HOURS_PER_28_DAYS_EXCEEDED"] = "EU_MAX_HOURS_PER_28_DAYS_EXCEEDED";
    // General rules
    SchedulingViolationType["MAX_HOURS_PER_PERIOD_EXCEEDED"] = "MAX_HOURS_PER_PERIOD_EXCEEDED";
    SchedulingViolationType["OVERLAPPING_SHIFTS"] = "OVERLAPPING_SHIFTS";
    SchedulingViolationType["INSUFFICIENT_REST_BETWEEN_SHIFTS"] = "INSUFFICIENT_REST_BETWEEN_SHIFTS";
    SchedulingViolationType["SHORT_DAY_WORKED"] = "SHORT_DAY_WORKED";
    SchedulingViolationType["MAX_HOURS_PER_DAY_EXCEEDED"] = "MAX_HOURS_PER_DAY_EXCEEDED";
})(SchedulingViolationType || (exports.SchedulingViolationType = SchedulingViolationType = {}));
