"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Presentation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const constants_1 = require("../../../../constants");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const Shift_1 = require("../components/Shift");
const useSchedule_1 = require("../useSchedule");
const useShiftPresets_1 = require("../useShiftPresets");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const CELL_STATUSES = [
    'TENDERED',
    'OCCUPIED',
    'SELECTED',
    'PRESENTED',
    'SELECTED',
    'REPLACING',
    'ACCEPTED',
    'REJECTED',
    'CANCELLED',
    'BOOKED',
];
const getEnhancedStatus = (status, mode, isSelected) => {
    const isPresenting = mode === 'create';
    const isReplacing = mode === 'replacing';
    const isAnswering = mode === 'answer';
    if (status === 'OCCUPIED')
        return 'OCCUPIED';
    if (status === 'CANCELLED')
        return 'CANCELLED';
    // Handling submitting presentations
    if (status === 'TENDERED') {
        if (isPresenting && isSelected)
            return 'SELECTED';
        return 'TENDERED';
    }
    if (status === 'BOOKED') {
        if (isReplacing && isSelected)
            return 'REPLACING';
        return 'BOOKED';
    }
    // Handling answering to presentations
    if (status === 'PRESENTED') {
        if (isAnswering && isSelected)
            return 'SELECTED';
        return 'PRESENTED';
    }
    // Assuming that the status is one of the PresentationCellStatus, we want the typing so we do not
    // accidentally return a different status in the above conditions, here it does not matter
    return status;
};
const toId = (value) => value.id;
const Presentation = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { days, currentWeek, shifts: allShifts, shiftPopover } = (0, useSchedule_1.useSchedule)();
    // For normal schedules without specific rows
    const { presets: shiftTypes } = (0, useShiftPresets_1.useShiftPresets)({ shifts: allShifts });
    // For tenders with defined schedule rows
    const rows = (0, lodash_1.uniq)(allShifts.map(({ rowKey }) => rowKey))
        .filter((rowKey) => typeof rowKey === 'number')
        .sort((a, b) => a - b);
    const selectedRows = allShifts
        .filter(({ id }) => props.selectedIds?.includes(id))
        .map(({ rowKey }) => rowKey)
        .filter((rowKey) => typeof rowKey === 'number');
    if (!currentWeek)
        return null;
    const shiftsInWeek = (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, allShifts);
    const renderWeek = (shifts, rowKey) => {
        return days.map((day) => {
            const shiftsOnDay = (0, lodash_1.groupBy)(shifts.filter((s) => s.date === day.dateISO), 'status');
            return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: !day.withinRange, weekend: day.isWeekend, children: (0, jsx_runtime_1.jsx)(CellShiftsColumn_1.CellShiftsColumn, { children: CELL_STATUSES.map((status) => {
                        const shifts = shiftsOnDay[status] ?? [];
                        if (!shifts.length)
                            return null;
                        const shiftIds = shifts.map(toId);
                        const shiftDetails = shifts[0];
                        const isOccupied = status === 'OCCUPIED';
                        const isUrgent = shifts.every((s) => s.isUrgent) && !isOccupied;
                        const errors = props.errors?.[day.dateISO]?.flat() ?? [];
                        // For strict row based tenders we only allow suppliers to present on one row
                        // per presentation. As soon as a row is selected, all other rows are disabled
                        const isSelectable = props.rows === 'STRICT' && typeof rowKey === 'number'
                            ? !selectedRows.length || selectedRows.includes(rowKey)
                            : true;
                        const isSelected = !!props.selectedIds?.includes(shiftDetails.id);
                        const enhancedStatus = getEnhancedStatus(status, props.mode, isSelected);
                        return ((0, jsx_runtime_1.jsx)(Shift_1.Shift, { startTime: shiftDetails.startTime, endTime: shiftDetails.endTime, interestedCandidates: shifts[0].interestedCandidates, popoverContent: shiftPopover, shiftIds: shiftIds, status: isSelectable ? enhancedStatus : 'BLOCKED', unselectable: isOccupied || !isSelectable, disabled: !isSelectable || isOccupied, onCallBadge: shiftDetails.onCallTime, tooltip: errors?.length
                                ? {
                                    intent: 'danger',
                                    message: (0, lodash_1.uniq)(errors.map((e) => t.$schedulingRuleViolation(e))).join(', '),
                                }
                                : undefined, onClick: props.onSelect && isSelectable && !isOccupied
                                ? () => props.onSelect?.(shiftIds)
                                : undefined, isUrgent: isUrgent }, enhancedStatus));
                    }) }) }, day.dateISO));
        });
    };
    if (props.rows === 'NONE') {
        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: shiftTypes.map((shiftType) => ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(elements_1.Flex, { styles: {
                                p: 0.5,
                                minHeight: 52,
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: constants_1.Color.DARK_GREY,
                            }, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, styles: { textAlign: 'center' }, children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, noWrap: true, fontSize: 14, children: `${shiftType.startTime} – ${shiftType.endTime}` }), (0, jsx_runtime_1.jsx)(elements_1.Text, { noWrap: true, fontWeight: 500, fontSize: 14, children: shiftType.breakTime
                                            ? t.break_n_min(shiftType.breakTime)
                                            : t.no_break() })] }) }) }), renderWeek(shiftsInWeek.filter((0, by_filter_1.byCellFilter)(shiftType)), undefined)] }, shiftType.key))) }));
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: rows.map((rowKey) => {
            const rowShifts = shiftsInWeek.filter((0, by_filter_1.byCellFilter)({ rowKey }));
            return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { title: t.shifts.row_n(rowKey + 1), shifts: null }) }), renderWeek(rowShifts, rowKey)] }, rowKey));
        }) }));
};
exports.Presentation = Presentation;
