"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAvailabilityStyle = void 0;
const enums_1 = require("@bemlo/enums");
const constants_1 = require("../../../../constants");
const translate_1 = require("../../../../translate");
const { VACATION, SCHEDULE } = enums_1.ResourceAvailabilitySource;
const useAvailabilityStyle = (availability) => {
    const t = (0, translate_1.useTranslations)();
    const isOnVacation = availability.some(({ source }) => source === VACATION);
    const isBlocked = availability.some(({ source, isAvailable }) => source === SCHEDULE && !isAvailable);
    const isAvailable = availability.some(({ isAvailable }) => isAvailable);
    if (isBlocked || isOnVacation) {
        return {
            icon: (isOnVacation ? 'TentIcon' : 'BanIcon'),
            title: t.availability.unavailable(),
            subtitle: isOnVacation
                ? t.availability.vacation()
                : t.availability.blocked(),
            color: constants_1.Color.CHARCOAL,
            backgroundColor: constants_1.Color.GREY,
        };
    }
    return {
        icon: (isAvailable ? 'HandIcon' : 'XIcon'),
        title: isAvailable
            ? t.availability.available()
            : t.availability.non_preferred(),
        subtitle: undefined,
        color: isAvailable ? constants_1.Color.DARK_GREEN : constants_1.Color.DARK_RED,
        backgroundColor: isAvailable ? '#F7FFFA' : constants_1.Color.WHITE,
    };
};
exports.useAvailabilityStyle = useAvailabilityStyle;
