"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Employment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const elements_1 = require("../../../elements");
const DatePicker_1 = require("../../../elements/Form/DatePicker");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const SELECTABLE_EMPLOYMENT_TYPES = [
    enums_1.EmploymentType.PERMANENT,
    enums_1.EmploymentType.HOURLY,
];
const Employment = () => {
    const t = (0, translate_1.useTranslations)();
    const { actor, data, isEditing, patchEditData } = (0, useCandidateView_1.useCandidateView)();
    const { contract } = data;
    const scope = scopes_1.scopes.contract();
    if (!actor.can('read', scope) || !contract) {
        return null;
    }
    const label = t.candidate_view.contract.employment_type();
    const fromLabel = t.candidate_view.contract.from();
    const untilLabel = t.candidate_view.contract.until();
    const icon = 'CheckIcon';
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, wrap: true, children: [(0, jsx_runtime_1.jsx)(elements_1.Combobox, { disabled: !actor.can('update', scope), label: label, labelIcon: icon, onChange: (employment) => employment &&
                        patchEditData({ contract: { ...contract, employment } }), options: SELECTABLE_EMPLOYMENT_TYPES.map((value) => ({
                        label: t.$employmentType(value),
                        value,
                        key: value,
                    })), value: contract.employment }), (0, jsx_runtime_1.jsx)(DatePicker_1.DatePicker, { label: fromLabel, labelIcon: "CalendarIcon", inputStyles: { width: 160, minWidth: 0 }, onChange: (startDate) => patchEditData({
                        contract: {
                            ...contract,
                            startsAt: startDate ? t.$date(startDate).unix() : null,
                        },
                    }), value: contract.startsAt
                        ? t.$date.unix(contract.startsAt).toDate()
                        : undefined, maxDate: contract.endsAt ? t.$date.unix(contract.endsAt).toDate() : undefined }), (0, jsx_runtime_1.jsx)(DatePicker_1.DatePicker, { label: untilLabel, labelIcon: "CalendarIcon", inputStyles: { width: 160, minWidth: 0 }, onChange: (endsAt) => patchEditData({
                        contract: {
                            ...contract,
                            endsAt: endsAt ? t.$date(endsAt).unix() : null,
                        },
                    }), value: contract.endsAt ? t.$date.unix(contract.endsAt).toDate() : undefined, minDate: contract.startsAt
                        ? t.$date.unix(contract.startsAt).toDate()
                        : undefined })] }));
    }
    const startsAt = contract.startsAt
        ? date_1.dayjsEn.unix(contract.startsAt).format(date_1.ISO_DATE_FORMAT)
        : null;
    const endsAt = contract.endsAt
        ? date_1.dayjsEn.unix(contract.endsAt).format(date_1.ISO_DATE_FORMAT)
        : null;
    const period = startsAt && endsAt
        ? `${startsAt} - ${endsAt}`
        : startsAt
            ? `${fromLabel} ${startsAt}`
            : endsAt
                ? `${untilLabel} ${endsAt}`
                : '';
    const content = [t.$employmentType(contract.employment), period].join(', ');
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.Employment = Employment;
