"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraftResource = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_1 = require("react");
const schedule_1 = require("@bemlo/schedule");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const DraftShifts_1 = require("../components/DraftShifts");
const NewShift_1 = require("../components/NewShift");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const ShiftError_1 = require("../components/ShiftError");
const useEditShifts_1 = require("../useEditShifts");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const DraftResource = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { days, currentWeek, shifts: allShifts, availability: allAvailability, shiftPopover, } = (0, useSchedule_1.useSchedule)();
    const { enableEditing, onDeleteDraftShift } = (0, useEditShifts_1.useEditShifts)(undefined);
    const [shifts, shiftsInWeek, dayErrors, assignedAvailability] = (0, react_1.useMemo)(() => {
        const shifts = allShifts.filter((0, by_filter_1.byCellFilter)(props));
        const shiftsInWeek = currentWeek
            ? (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, shifts)
            : shifts;
        const assignedAvailability = props.assignedResource
            ? allAvailability.filter(({ candidateId }) => candidateId === props.assignedResource?.id)
            : [];
        const dayErrors = props.schedulingRules
            ? (0, schedule_1.getRuleViolationsMap)(props.schedulingRules.ruleSets, shifts, props.schedulingRules.rules, props.schedulingRules.resource)
            : {};
        return [shifts, shiftsInWeek, dayErrors, assignedAvailability];
    }, [allShifts, props, currentWeek]);
    const name = props.assignedResource?.fullName ?? t.shifts.row_n(props.rowKey + 1);
    const title = ((0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { title: name, shifts: shiftsInWeek, bold: !!props.assignedResource }));
    const HoverCardContent = props.hoverCard;
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: HoverCardContent ? ((0, jsx_runtime_1.jsx)(elements_1.HoverCard, { side: "right", trigger: (0, jsx_runtime_1.jsx)(elements_1.HoverCard.Trigger, { children: title }), children: (0, jsx_runtime_1.jsx)(HoverCardContent, { candidateId: null, name: name, shifts: shifts, availability: [], ...(props.hoverCardProps || {}) }) })) : (title) }), days.map((day) => {
                const [draftShifts, shiftsOnDay] = (0, lodash_1.partition)(shifts.filter((shift) => shift.date === day.dateISO), ({ status }) => status === 'DRAFT');
                const hasShifts = !!draftShifts.length || !!shiftsOnDay.length;
                const errorsOnDay = dayErrors[day.dateISO]?.map((error) => ({
                    error: t.$schedulingRuleViolation(error),
                    isWarning: error.severity === 'warning',
                })) ?? [];
                const availabilityOnDay = assignedAvailability.filter(({ date }) => date === day.dateISO);
                return ((0, jsx_runtime_1.jsx)(Cell_1.AvailabilityCell, { disabled: !day.withinRange, weekend: day.isWeekend, availability: availabilityOnDay, isOccupied: hasShifts, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [shiftsOnDay.map((shift) => {
                                const isArchived = shift.status === 'ARCHIVED';
                                const isUrgent = shift.isUrgent && !isArchived;
                                if (!shift)
                                    return null;
                                const isAtOtherUnit = shift.unitId !== props.homeUnitId;
                                return ((0, jsx_runtime_1.jsx)(NewShift_1.NewShift, { shift: shift, interestedCandidates: shift.interestedCandidates, isAtOtherUnit: isAtOtherUnit, unitName: isAtOtherUnit
                                        ? shift.unitName
                                        : shift.scheduleResourceName, popoverContent: shiftPopover, shiftIds: [shift.id], status: shift.status, isUrgent: isUrgent, unselectable: isArchived, error: (0, jsx_runtime_1.jsx)(ShiftError_1.ShiftError, { errors: errorsOnDay }) }, shift.id));
                            }), enableEditing && day.withinRange && ((0, jsx_runtime_1.jsx)(DraftShifts_1.DraftShift, { showBadges: true, shifts: draftShifts, onClickNewShift: () => props.onClickNewShift?.(day.dateISO), onClickShift: props.onClickShift, onDeleteDraftShift: onDeleteDraftShift }))] }) }, day.dateISO));
            })] }));
};
exports.DraftResource = DraftResource;
