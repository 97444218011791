"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEUWorkloadRuleViolations = exports.getWorkloadRuleViolations = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const getWorkloadRuleViolations = (shifts, { fullTimeWeekly, serviceLevel }) => {
    // If no full time hours or service level is set, we can't calculate the expected work time
    if (!fullTimeWeekly || !serviceLevel) {
        return [];
    }
    const startDate = shifts[0].startDateTime;
    const endDate = shifts[shifts.length - 1].endDateTime;
    // Pass period as configuration? TODO(BEMLO-3833)
    const numberOfWeeks = Math.ceil(endDate.endOf('week').diff(startDate.startOf('week'), 'days') / 7);
    const expectedWorkTime = fullTimeWeekly * serviceLevel;
    const expectedWorkTimeTotal = expectedWorkTime * numberOfWeeks;
    const actualWorkTime = shifts.reduce((acc, shift) => acc + (0, utils_1.hoursAsMinutes)(shift.durations.scheduled.asHours()), 0);
    if (actualWorkTime < expectedWorkTimeTotal) {
        // TODO(BEMLO-3829): add WORKLOAD_UNDERLOAD to SchedulingViolationType
        // return [
        //   {
        //     date: new Set([startDate.format(ISO_DATE_FORMAT)]),
        //     severity: 'warning',
        //     details: { restTime: actualWorkTime, },
        //     type: SchedulingViolationType.WORKLOAD_UNDERLOAD,
        //   },
        // ]
        return [];
    }
    if (actualWorkTime > expectedWorkTimeTotal) {
        return [
            {
                date: new Set([startDate.format(date_1.ISO_DATE_FORMAT)]),
                shiftId: new Set(shifts.map(({ id }) => id)),
                severity: 'error',
                details: { overtime: actualWorkTime - expectedWorkTimeTotal },
                type: enums_1.SchedulingViolationType.MAX_HOURS_PER_PERIOD_EXCEEDED,
            },
        ];
    }
    return [];
};
exports.getWorkloadRuleViolations = getWorkloadRuleViolations;
// Max allowed average hours per week over any 4-week period according to EU working time directive
const MAX_AVERAGE_HOURS_PER_WEEK = 48;
// Produce an sliding window of 4 weeks, and calculate the average hours worked in each window
//  -  If the the total time is less than the max average over any 4 week period.
//  -  If any of the windows exceed the maximum allowed average hours per week, return a
//     violation of type EU_MAX_HOURS_PER_28_DAYS_EXCEEDED,
const getEUWorkloadRuleViolations = (shifts) => {
    const totalTime = (0, lodash_1.sumBy)(shifts, (shift) => shift.durations.scheduled.asHours());
    if (totalTime < MAX_AVERAGE_HOURS_PER_WEEK * 4) {
        return [];
    }
    // We’ll slide over each shift’s start time as a potential “windowStart”
    for (let i = 0; i < shifts.length; i++) {
        const windowStart = shifts[i].startDateTime;
        const windowEnd = windowStart.add(28, 'days');
        // Sum total hours of all shifts *within* [windowStart, windowEnd)
        let totalHoursInWindow = 0;
        for (const shift of shifts) {
            const shiftStart = shift.startDateTime;
            const shiftEnd = shift.endDateTime;
            // If a shift has zero overlap with [windowStart, windowEnd), skip it
            if (shiftEnd.isBefore(windowStart) || shiftStart.isAfter(windowEnd)) {
                continue;
            }
            // Calculate the partial overlap
            const overlapStart = shiftStart.isBefore(windowStart)
                ? windowStart
                : shiftStart;
            const overlapEnd = shiftEnd.isAfter(windowEnd) ? windowEnd : shiftEnd;
            const hoursOverlap = overlapEnd.diff(overlapStart, 'hours', true);
            if (hoursOverlap > 0) {
                totalHoursInWindow += hoursOverlap;
            }
        }
        // Average hours per week in this 28-day window:
        const avgHoursPerWeek = totalHoursInWindow / 4;
        if (avgHoursPerWeek > MAX_AVERAGE_HOURS_PER_WEEK) {
            // Found a violation; we can return immediately.
            return [
                {
                    // Date is somewhat arbitrary. If you want to mark the entire window,
                    // you could store multiple dates or store the “windowStart” alone.
                    date: new Set([windowStart.format(date_1.ISO_DATE_FORMAT)]),
                    shiftId: new Set(shifts.map(({ id }) => id)),
                    severity: 'error',
                    type: enums_1.SchedulingViolationType.EU_MAX_HOURS_PER_28_DAYS_EXCEEDED,
                    details: {
                        restTime: avgHoursPerWeek - MAX_AVERAGE_HOURS_PER_WEEK,
                    },
                },
            ];
        }
    }
    // If we checked every 4-week window without finding a violation, return []
    return [];
};
exports.getEUWorkloadRuleViolations = getEUWorkloadRuleViolations;
