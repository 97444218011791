"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scope = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const DigitInput_1 = require("../../../elements/Form/DigitInput");
const hooks_1 = require("../../../hooks");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const Scope = () => {
    const t = (0, translate_1.useTranslations)();
    const z = (0, hooks_1.useZod)();
    const { actor, data, isEditing, patchEditData } = (0, useCandidateView_1.useCandidateView)();
    const { contract } = data;
    const scope = scopes_1.scopes.contract();
    if (!actor.can('read', scope) || !contract) {
        return null;
    }
    const label = t.candidate_view.contract.scope();
    const icon = 'InProgress';
    const fullTimeLabel = t.candidate_view.contract.full_time_hours();
    const serviceLevelLabel = t.candidate_view.contract.service_level();
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, wrap: true, children: [(0, jsx_runtime_1.jsx)(DigitInput_1.DigitInput, { decimals: true, label: `${fullTimeLabel} (${t.candidate_view.contract.unit_hours_per_week()})`, value: contract.fullTimeWeekly / 60, onChange: (value = 0) => patchEditData({
                        contract: { ...contract, fullTimeWeekly: value * 60 },
                    }), schema: z.number().min(0).max(168) }), (0, jsx_runtime_1.jsx)(DigitInput_1.DigitInput, { decimals: true, label: `${fullTimeLabel} (${t.candidate_view.contract.unit_percentage()})`, value: contract.serviceLevel * 100, onChange: (value = 0) => patchEditData({
                        contract: { ...contract, serviceLevel: value / 100 },
                    }), schema: z.number().min(0).max(100) })] }));
    }
    const content = `${fullTimeLabel} ${t.$n_hours(contract.fullTimeWeekly / 60)}, ${serviceLevelLabel} ${contract.serviceLevel * 100} %`;
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.Scope = Scope;
