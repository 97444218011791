"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schedulingViolationType = void 0;
exports.schedulingViolationType = {
    EU_MAX_HOURS_PER_28_DAYS_EXCEEDED: 'Max 48 tim snitt per 4 veckor',
    EU_NO_COMPENSATING_REST: 'Kompensationsvila',
    EU_NO_DAY_REST: 'Dygnsvila',
    EU_NO_WEEK_REST: 'Veckovila',
    EU_REDUCED_DAY_REST: 'Dygnsvila',
    INSUFFICIENT_REST_BETWEEN_SHIFTS: 'Vila mellan pass',
    MAX_HOURS_PER_DAY_EXCEEDED: 'För lång arbetsdag',
    MAX_HOURS_PER_PERIOD_EXCEEDED: 'Övertid',
    OVERLAPPING_SHIFTS: 'Överlappande pass',
    SHORT_DAY_WORKED: 'För kort dag',
};
