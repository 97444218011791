"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.availability_schedule = exports.jobs = exports.timesheets = exports.shifts = exports.availability = exports.unit_relation = exports.candidate_view = exports.common = exports.chat_view = void 0;
const enums_1 = require("@bemlo/enums");
const i18n_1 = require("@bemlo/i18n");
const utils_1 = require("@bemlo/utils");
const list_1 = require("../list");
const LOCALE = enums_1.Locale.EN;
const $country = (0, i18n_1.translateCountryFactory)(LOCALE);
const $licenseStatus = (0, i18n_1.translateUsingBothKeyAndValue)(enums_1.LicenseStatus, {
    [enums_1.LicenseStatus.NO_LICENSE]: 'Missing license',
    [enums_1.LicenseStatus.HAS_APPLIED]: 'Has applied for license',
    [enums_1.LicenseStatus.HAS_LICENSE]: 'Has license',
});
const $list = (0, list_1.listFactory)('and');
const $market = (market) => $country((0, utils_1.countryForMarket)(market));
const $profession = (0, i18n_1.translateProfessionFactory)(LOCALE);
exports.chat_view = {
    conversation_other: () => 'Conversations',
    internal: () => 'Internal',
    internal_conversation: () => 'Internal conversation',
    join: () => 'Join',
    new: () => 'New',
    new_conversation: () => 'New conversation',
    no_conversation_heading: (isBrowsable) => isBrowsable ? 'No conversation selected' : 'No conversation',
    no_conversation_paragraph: (isBrowsable, isCreatable) => ({
        'false,false': 'There is no conversation here yet.',
        'false,true': 'Start a new conversation to get started.',
        'true,false': 'Click on a conversation to get started.',
        'true,true': 'Select an existing conversation or start a new one.',
    })[`${isBrowsable},${isCreatable}`],
    no_subject: () => 'no subject',
    send: () => 'Send',
    start_conversation: () => 'Start conversation',
    write_a_message: () => 'Write a message...',
};
exports.common = {
    accepted_scope: () => 'Accepted scope',
    booked_scope: () => 'Booked scope',
    close: () => 'Close',
    minutes: () => 'Minutes',
    percent_sign: (value) => `${value}%`,
    period: () => 'Period',
    presented_scope: () => 'Presented scope',
    remaining_scope: () => 'Remaining scope',
    scope: () => 'Scope',
    search: () => 'Search',
    tendered_scope: () => 'Tendered scope',
    this_month: () => 'This month',
    this_week: () => 'This week',
    time_of_day: () => 'Time of day',
    today: () => 'Today',
    total: () => 'Total',
};
exports.candidate_view = (() => {
    const base = {
        authorized: () => 'Authorized',
        cancel: () => 'Cancel',
        drivers_license: () => "Driver's licence",
        last_updated: () => 'Last updated',
        save: () => 'Save',
    };
    const biography = {
        about_me: () => 'About me',
        about_the_candidate: () => 'About the candidate',
        and_n_more: (n) => `and ${n} more`,
        applied_for_license: () => 'Applied for licence',
        biography: () => 'About',
        country: () => 'Country',
        current_employment: () => 'Current employment',
        currently_employed: () => 'Currently employed',
        has_applied_for_license_in: (markets, hasAnyLicense) => `has applied ${hasAnyLicense ? 'for licence ' : ''}in ${$list(markets.map($market))}`,
        home_municipality: () => 'Home municipality',
        journal_system_other: () => 'Journal systems',
        journal_systems: () => 'Journal systems',
        licensed: (profession) => `Licenced ${profession
            ? `${$profession(profession, 1).toLowerCase()}`
            : 'practitioner'}`,
        licensed_in: (markets) => `licenced in ${$list(markets.map($market))}`,
        n_years_of_experience: (years) => `${years}+ years of experience`,
        name: () => 'Name',
        no_biography: () => 'no biography',
        norwegian_license: () => 'Norwegian licence',
        profession: () => 'Profession',
        send_message: () => 'Message',
        specialization: () => 'Specialization',
        swedish_license: () => 'Swedish licence',
        write_a_biography: () => 'Write a short biography about yourself...',
        years_of_experience: () => 'Years of experience',
    };
    const candidate_type = (type) => {
        const translations = {
            [enums_1.CandidateType.BOOKED]: 'Booked',
            [enums_1.CandidateType.MANAGED]: 'Managed',
            [enums_1.CandidateType.MIRRORED]: 'Contact',
            [enums_1.CandidateType.SOVEREIGN]: 'Profile',
            [enums_1.CandidateType.STATIC]: 'ATLAS',
            [enums_1.CandidateType.SYNCED]: 'CRM',
            [enums_1.CandidateType.EMPLOYED]: 'Employed',
        };
        return translations[type];
    };
    const candidate_type_action = (type) => {
        const translations = {
            [enums_1.CandidateType.BOOKED]: 'booked',
            [enums_1.CandidateType.MANAGED]: 'added',
            [enums_1.CandidateType.MIRRORED]: 'unlocked',
            [enums_1.CandidateType.SOVEREIGN]: '',
            [enums_1.CandidateType.STATIC]: 'unlocked',
            [enums_1.CandidateType.SYNCED]: 'synced',
            [enums_1.CandidateType.EMPLOYED]: 'invited',
        };
        return translations[type];
    };
    const contact_requests = {
        contact_request_other: () => 'Contact requests',
        n_new: (count) => `${count} new!`,
    };
    const edit = {
        you_are_editing_candidate: (name) => `You are editing ${name ?? 'your profile'}.`,
    };
    const file_uploads = {
        documents: () => 'Documents',
        fetched_at: (date) => `Fetched ${date}`,
        upload: () => 'Upload...',
        upload_document: () => 'New document',
        uploaded_at: (date) => `Uploaded ${date}`,
    };
    const information = {
        add_a_personal_identity_number: () => 'Add a personal identity number',
        add_a_phone_number: () => 'Add a phone number',
        add_an_email: () => 'Add an email',
        add_authorization_year: () => 'Add authorization year',
        authorized_since: () => 'Authorized since',
        authorized_since_placeholder: () => 'Choose year...',
        email: () => 'Email',
        email_placeholder: () => 'E.g. name@company.com',
        hpr_number: () => 'HPR number',
        information: () => 'Information',
        no_information: () => 'There is no information to show.',
        personal_identity_number: () => 'Personal identity number',
        personal_identity_number_placeholder: () => 'YYYYMMDD-XXXX',
        phone_number: () => 'Phone number',
        phone_number_placeholder: () => 'E.g. +44 20 7123 4567',
    };
    const interests = {
        bemlo_job_matcher: () => 'Bemlo Job Matcher',
        candidate_interests: () => 'Candidate interests',
        country_other: () => 'Countries',
        department_other: () => 'Departments',
        earliest_start_date: () => 'Earliest start date',
        extent: () => 'Extent',
        interests: () => 'Interests',
        job_type: (jobType) => {
            const translations = {
                [enums_1.JobType.IRREGULAR_PARTTIME]: 'Irregular part-time jobs',
                [enums_1.JobType.JOUR]: 'On-call jobs',
                [enums_1.JobType.PERMANENT]: 'Permanent jobs',
                [enums_1.JobType.STAFFING]: 'Staffing jobs',
                [enums_1.JobType.TEMPORARY]: 'Temporary jobs',
            };
            return translations[jobType];
        },
        job_type_other: () => 'Type of employment',
        make_my_contact_information_visible_to_employers: () => 'Make my contact information visible to employers',
        municipality_other: () => 'Municipalities',
        open_for_work_from: () => 'Open for work from',
        open_to_work_from: (date) => `Open to work from ${date}`,
        region_other: () => 'Regions',
        subscribe_to_matching_jobs: () => 'Subscribe to matching jobs',
        we_will_notify_you_once_a_day: () => 'We will notify you once a day',
        what_jobs_are_you_interested_in: () => 'What jobs are you interested in?',
        work_form_other: () => 'Work forms',
        you_may_be_contacted_regarding_job_opportunities: () => 'You may be contacted regarding job opportunities',
    };
    const contract = {
        contract: () => 'Contract',
        default_day_break: () => 'Default day break',
        default_week_break: () => 'Default week break',
        employment_type: () => 'Employment type',
        from: () => 'From',
        full_time_hours: () => 'Full time',
        period: () => 'period',
        scope: () => 'Scope',
        service_level: () => 'Service level',
        shift_hours: () => 'Shift hours',
        unit_hours_per_week: () => 'hours/week',
        unit_percentage: () => '%',
        until: () => 'Until',
        working_time_directive: () => 'Working time directive',
    };
    const license_status = $licenseStatus;
    const profile_progress = {
        add_authorization: () => 'Add authorisation',
        invite_a_reference: () => 'Invite a reference',
        maximize_your_chances: () => 'Maximize your chances of finding your dream job.',
        upload_cv: () => 'Upload CV',
        upload_license: () => 'Upload licence',
        your_profile_is_n_percent_done: (percent) => `Your profile is ${percent}% done`,
        your_profile_looks_empty: () => 'Your profile looks empty',
    };
    const references = {
        add_reference: () => 'Add reference',
        added: () => 'Added',
        added_at: (when) => `Added ${when}`,
        n_attachments: (attachments) => `${attachments} attachment${attachments === 1 ? '' : 's'}`,
        n_questions_answered: (count) => `${count} question${count === 1 ? '' : 's'} answered`,
        no_references: () => 'There are no references to show.',
        no_references_added: () => 'There are no references added yet.',
        references: () => 'References',
        reported_at: (when, isManaged) => `Reported ${isManaged ? '' : 'by the candidate '}${when}`,
        waiting_for_response: () => 'Waiting for response...',
    };
    const summary_section = {
        locked: () => 'Locked',
    };
    const vacancies = {
        based_on_the_candidates_profile: () => "Based on the candidate's profile",
        based_on_your_profile: () => 'Based on your profile',
        jobs_for_you: () => 'Jobs for you',
        matching_vacancies: () => 'Matching vacancies',
        n_percent_match: (percent) => `${percent}% match`,
        no_title: () => 'no title',
        region_other: () => 'Regions',
        see_n_matching_jobs: (count) => `See ${count ?? 'all'} matching jobs`,
        show_more: () => 'Show more',
    };
    return {
        base,
        biography,
        candidate_type,
        candidate_type_action,
        contact_requests,
        contract,
        edit,
        file_uploads,
        information,
        interests,
        license_status,
        profile_progress,
        references,
        summary_section,
        vacancies,
    };
})();
const unit_relation = (type) => {
    const translations = {
        [enums_1.UnitRelation.HOME_UNIT]: 'Home Unit',
        [enums_1.UnitRelation.CROSS_UNIT]: 'Cross Unit',
        [enums_1.UnitRelation.CROSS_ORG]: 'Cross Org',
    };
    return translations[type];
};
exports.unit_relation = unit_relation;
exports.availability = {
    available: () => 'Available',
    blocked: () => 'Blocked',
    non_preferred: () => 'Non-preferred',
    scheduled: () => 'Scheduled',
    unavailable: () => 'Unavailable',
    vacation: () => 'Vacation',
};
exports.shifts = {
    absent: () => 'Absent',
    accepted: () => 'Accepted',
    accepting: () => 'Accepting',
    add_many: () => 'Add many shifts',
    add_many_description: (everyNWeeks, periodStart) => `Adding shifts ${everyNWeeks === 1 ? 'weekly' : `every ${everyNWeeks} weeks`} starting from ${periodStart}`,
    add_many_period_start: () => 'Period start',
    add_many_periodicity: () => 'Periodicity',
    add_many_periodicity_placeholder: () => "E.g. '2' for every other week",
    applied: () => 'Applied',
    archived: () => 'Archived',
    available: () => 'Available',
    blocked: () => 'Blocked',
    book: () => 'Book',
    booked: () => 'Booked',
    cancelled: () => 'Cancelled',
    day: () => 'Day',
    draft: () => 'Draft',
    external_resource_other: () => 'External resources',
    flex_shift: () => 'Flex shift',
    make_fixed: () => 'Make fixed',
    new: () => 'New',
    new_row: () => 'New row',
    night: () => 'Night',
    no_shifts: () => 'No shifts',
    not_published: () => 'Not Published',
    occupied: () => 'Occupied',
    presented: () => 'Presented',
    published: () => 'Published',
    reject: () => 'Reject',
    rejected: () => 'Rejected',
    rejecting: () => 'Rejecting',
    remove_fixed: () => 'Remove fixed',
    replace: () => 'Replace',
    replacing: () => 'Replacing',
    requires_meds_training: () => 'Requires meds training',
    row_n: (n) => `Row ${n}`,
    selected: () => 'Selected',
    shift_one: () => 'Shift',
    shift_other: () => 'Shifts',
    suggested: () => 'Suggested',
    template: () => 'Template',
    tendered: () => 'Tendered',
    twilight: () => 'Twilight',
    unavailable: () => 'Unavailable',
    unselected: () => 'Unselected',
    urgent: () => 'Urgent',
    vacant_one: () => 'Vacant',
    vacant_other: () => 'Vacant',
};
exports.timesheets = {
    attested: () => 'Attested',
    break_time: () => 'Break time',
    consultant_manager: () => 'Consultant manager',
    end_time: () => 'End time',
    reported: () => 'Reported',
    resource: () => 'Resource',
    returned: () => 'Returned',
    start_time: () => 'Start time',
    time_report_log: () => 'Time report log',
    unit_manager: () => 'Unit manager',
    updated: () => 'Updated',
};
exports.jobs = {
    apply_by: () => 'Apply by',
    apply_directly_on_the_website: () => 'Apply directly on Bemlo',
    estimated_salary: () => 'Estimated',
    explore_similar_jobs: () => 'Explore other similar jobs',
    full_time_description: () => 'The assignment is for 100%',
    gathered_best_jobs_for_you_description: () => 'We have gathered staffing jobs from multiple staffing agencies, so you can find the assignment that suits you best.',
    irregular_part_time_description: () => 'The assignment involves occasional shifts here and there',
    job_location: (municipality) => `Staffing assignment in ${municipality}`,
    logo_of_employer: () => 'Logo of employer',
    match: () => 'Match',
    new: () => 'New!',
    no_description: () => 'No description',
    open_map: () => 'Open map',
    published_at_date: (date) => `Published ${date}`,
    regular_part_time_description: () => 'The assignment is for between 20-80%',
    the_job_is_located_in_municipality: (municipality) => `The assignment takes place in ${municipality}`,
    week_number: (week) => `Week ${week}`,
    your_workplace: () => 'Your workplace',
};
exports.availability_schedule = {
    schema_error_scope_must_be_greater_than_or_equal_to: (scope) => `Scope must be greater than or equal to ${scope}.`,
    schema_error_scope_must_be_less_than_or_equal_to: (scope) => `Scope must be less than or equal to ${scope}.`,
    schema_error_select_at_least_one_day: () => 'Select at least one day.',
    schema_error_select_at_least_one_shift_hours_type: () => 'Select at least one shift type.',
};
