"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schedulingViolationType = void 0;
exports.schedulingViolationType = {
    EU_MAX_HOURS_PER_28_DAYS_EXCEEDED: 'Maks 48-timers gjennomsnitt per 4 uker',
    EU_NO_COMPENSATING_REST: 'Kompensasjonshvile',
    EU_NO_DAY_REST: 'Døgnhvile',
    EU_NO_WEEK_REST: 'Ukehvile',
    EU_REDUCED_DAY_REST: 'Døgnhvile',
    INSUFFICIENT_REST_BETWEEN_SHIFTS: 'Hvile mellom vakter',
    MAX_HOURS_PER_DAY_EXCEEDED: 'For lang arbeidsdag',
    MAX_HOURS_PER_PERIOD_EXCEEDED: 'Overtid',
    OVERLAPPING_SHIFTS: 'Overlappende vakter',
    SHORT_DAY_WORKED: 'For kort arbeidsdag',
};
