"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StickyHeaderWhenScrolled = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@emotion/react");
const react_2 = require("@headlessui/react");
const react_3 = require("react");
const Div_1 = require("./Div");
const constants_1 = require("../constants");
const StickyHeaderWhenScrolled = ({ children, triggerRef, styles, }) => {
    const [show, setShow] = (0, react_3.useState)(false);
    (0, react_3.useEffect)(() => {
        const element = triggerRef.current;
        if (!element)
            return;
        const observer = new IntersectionObserver(([entry]) => {
            setShow(!entry.isIntersecting);
        }, {
            threshold: 0,
        });
        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [triggerRef]);
    return ((0, jsx_runtime_1.jsx)(Div_1.Div, { styles: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            ...styles,
        }, children: (0, jsx_runtime_1.jsx)(react_1.ClassNames, { children: ({ css }) => ((0, jsx_runtime_1.jsx)(react_2.Transition, { show: show, className: css `
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              transition: transform ${constants_1.theme.animation.transition};
            `, leaveFrom: css `
              transform: translateY(0);
            `, leaveTo: css `
              transform: translateY(-100%);
            `, enterFrom: css `
              transform: translateY(-100%);
            `, enterTo: css `
              transform: translateY(0);
            `, children: children })) }) }));
};
exports.StickyHeaderWhenScrolled = StickyHeaderWhenScrolled;
