"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FLOW_RULES_AND_RESOURCE_CONFIGURATION = void 0;
const utils_1 = require("@bemlo/utils");
const rules_1 = require("../rules");
const rule_sets_1 = require("./rule-sets");
const FLOW_RULES_AND_RESOURCE_CONFIGURATION = {
    ruleSets: rule_sets_1.FLOW_RULE_SET,
    rules: (0, rules_1.schedulingRules)({ minimumRestBetweenShifts: (0, utils_1.hoursAsMinutes)(11) }),
    resource: {
        dayBreak: '08:00',
        weekBreak: '3 08:00',
        fullTimeWeekly: (0, utils_1.hoursAsMinutes)(40),
        serviceLevel: 1,
    },
};
exports.FLOW_RULES_AND_RESOURCE_CONFIGURATION = FLOW_RULES_AND_RESOURCE_CONFIGURATION;
