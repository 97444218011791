"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActiveTime = exports.getSlots = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const getActiveTime = (shifts) => {
    return shifts.flatMap(({ id: shiftId, startDateTime, endDateTime, onCallTime }) => {
        if (!onCallTime) {
            return [{ shiftId, startsAt: startDateTime, endsAt: endDateTime }];
        }
        const onCallTimeInStart = startDateTime.isSame(onCallTime.startDateTime, 'minute');
        const onCallTimeInEnd = endDateTime.isSame(onCallTime.endDateTime, 'minute');
        // Only on call, no active time
        if (onCallTimeInStart && onCallTimeInEnd) {
            return [];
        }
        if (onCallTimeInStart) {
            return [
                {
                    shiftId,
                    startsAt: onCallTime.endDateTime,
                    endsAt: endDateTime,
                },
            ];
        }
        if (onCallTimeInEnd) {
            return [
                {
                    shiftId,
                    startsAt: startDateTime,
                    endsAt: onCallTime.startDateTime,
                },
            ];
        }
        // If the on call time is the middle of the shift
        return [
            {
                shiftId,
                startsAt: startDateTime,
                endsAt: onCallTime.startDateTime,
            },
            {
                shiftId,
                startsAt: onCallTime.endDateTime,
                endsAt: endDateTime,
            },
        ];
    });
};
exports.getActiveTime = getActiveTime;
const assignActiveTimesToSlots = (slots, activeTimes, slotLength) => {
    const slotStartTime = slots[0].startsAt;
    for (const activeTime of activeTimes) {
        const startDiffHours = activeTime.startsAt.diff(slotStartTime, 'hours', true);
        const endDiffHours = activeTime.endsAt.diff(slotStartTime, 'hours', true);
        const startIndex = Math.floor(startDiffHours / slotLength);
        const endIndex = Math.floor(endDiffHours / slotLength);
        for (let i = startIndex; i <= endIndex; i++) {
            if (i >= 0 && i < slots.length) {
                slots[i].activeTime.push(activeTime);
            }
        }
    }
};
const calculateTimeSlotMaxRestTime = (slots, fullRest) => {
    for (let slotI = 0; slotI < slots.length; slotI++) {
        const slot = slots[slotI];
        // No active time, chill!
        if (!slot.activeTime.length) {
            slots[slotI].restTime = fullRest;
            continue;
        }
        let restTime = slots[slotI].restTime;
        for (let activeTimeI = 0; activeTimeI < slot.activeTime.length; activeTimeI++) {
            const prevActiveTime = activeTimeI > 0 && slot.activeTime[activeTimeI - 1];
            const activeTime = slot.activeTime[activeTimeI];
            const nextActiveTime = slot.activeTime[activeTimeI + 1];
            // first active time and it starts after the slot begins
            if (!prevActiveTime && activeTime.startsAt.isAfter(slot.startsAt)) {
                const restBeforeActiveTime = activeTime.startsAt.diff(slot.startsAt, 'hours', true);
                restTime = (0, lodash_1.max)([restBeforeActiveTime, restTime]) ?? 0;
            }
            if (!nextActiveTime && activeTime.endsAt.isBefore(slot.endsAt)) {
                const restTimeBeforeEnd = slot.endsAt.diff(activeTime.endsAt, 'hours', true);
                restTime = (0, lodash_1.max)([restTimeBeforeEnd, restTime]) ?? 0;
            }
            const restTimeBetweenActiveTimes = nextActiveTime?.startsAt.diff(activeTime.endsAt, 'hours') ?? 0;
            restTime = (0, lodash_1.max)([restTime, restTimeBetweenActiveTimes]) ?? 0;
        }
        slots[slotI].restTime = restTime;
    }
};
const getSlots = (firstSlotStartsAt, numberOfSlots, slotLength, activeTimes) => {
    const slots = Array.from({ length: numberOfSlots }).map((_, i) => {
        const startsAt = firstSlotStartsAt.add(i * slotLength, 'hours');
        const endsAt = startsAt.add(slotLength, 'hours');
        return {
            date: startsAt.format(date_1.ISO_DATE_FORMAT),
            startsAt,
            endsAt,
            restTime: 0,
            activeTime: [],
        };
    });
    // These are a bit bad since they updating by reference...
    assignActiveTimesToSlots(slots, activeTimes, slotLength);
    calculateTimeSlotMaxRestTime(slots, slotLength);
    return slots.map((slot) => ({
        ...slot,
        // Convert rest time to minutes as that's the unit we use in the scheduling rules
        restTime: slot.restTime * 60,
    }));
};
exports.getSlots = getSlots;
