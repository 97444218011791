"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ui = void 0;
exports.ui = {
    break_n_min: (breakTime) => `Break: ${breakTime} min`,
    cancel: () => 'Cancel',
    confirm: () => 'Confirm',
    filter_ellipsis: () => 'Filter...',
    hours: (hours) => (hours === 1 ? 'hour' : 'hours'),
    hours_short: () => 'h',
    includes: () => 'includes',
    includes_all_of: () => 'includes all of',
    includes_any_of: () => 'includes any of',
    includes_none_of: () => 'includes none of',
    is: () => 'is',
    is_after: () => 'is on or after',
    is_any_of: () => 'is any of',
    is_before: () => 'is on or before',
    is_not: () => 'is not',
    is_not_part_of: () => 'is not part of',
    is_part_of: () => 'is part of',
    minutes: (minutes) => (minutes === 1 ? 'minute' : 'minutes'),
    minutes_short: () => 'm',
    n_shifts_available: (n) => `${n} vacant shift${n > 1 ? 's' : ''}`,
    no_alternatives: () => 'No alternatives',
    no_break: () => 'No break',
    not_includes: () => 'does not include',
    optional: () => 'optional',
    reply: () => 'Reply',
    reply_from_x: (name) => `Reply from ${name}`,
    selected_one: () => 'Selected',
    shift_acknowledged_by_candidate: () => 'Shift booking acknowledged by candidate',
    week: (number) => `Week${number !== undefined ? ` ${number}` : ''}`,
};
